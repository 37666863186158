import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: HomeView
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
    // 首页 登录
    {
        path: "/",
        name: "index",
        component: resolve => require(['@views/index'], resolve),
        meta: {title:'少女虫拍客空间',keepAlive: false},
    },
    {
        path: "/map",
        name: "map",
        component: resolve => require(['@views/map'], resolve),
        meta: {title:'少女虫拍客空间',keepAlive: false},
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//     /* 路由发生变化修改页面title */
//     if (to.meta.title) {
//       document.title = to.meta.title
//     }
//     console.log(to)
//     console.log(from)
//     if(to.path == '/index'){
//         // 跳转的是登陆
//         next()
//     }else{
//         // 跳转的不是登录
//         // 判断是否有token
//         // let token = store.state.token;
//         let token = localStorage.getItem('token');
//         console.log(token)
//         if(token){
//             next()
//         }else{
//             next({
//                 path: '/index',
//             })
//         }
//     }
    
// })

export default router
