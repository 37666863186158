<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
};
</script>

<style lang="less">
// * {
// 	font-family: "Bold";
// }
#app {
	width: 100%;
	// height: 1920px;
}
body {
	padding: 0;
	margin: 0;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-khtml-user-select: none;
	user-select: none;
}

img {
	-webkit-user-drag: none;

	-webkit-user-select: none;

	-moz-user-select: none;

	-ms-user-select: none;

	user-select: none;
}
// #app {
//   width: 100%;
// }
// @media screen and (max-width:768px){
//   #app {
//     width: 1920px;
//   }
// }
/* 隐藏垂直滚动条 */

body::-webkit-scrollbar {
	width: 0;
}

/* 隐藏水平滚动条 */
div::-webkit-scrollbar {
	height: 0;
}

// .transition-enter,.transition-enter-active,.v-enter-to,.v-leave,.v-leave-active,.v-leave-to

.transition-enter-active,
.transition-leave-active {
	transition: opacity 0.5s;
}

.transition-enter-from,
.transition-leave-to {
	opacity: 0;
}

.FONT {
	background: -webkit-linear-gradient(top, #7875fc, #c4aaff);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
</style>
