import Vue from 'vue'
// 横向拖拽
export const rollX = Vue.directive('rollX', {
  inserted (el) {
    // 获取要绑定事件的元素
    var flag; // 鼠标按下
    var downX; // 鼠标点击的x下标
    var scrollLeft; // 当前元素滚动条的偏移量
    let firstTime='',lastTime='';
    el.addEventListener("mousedown", function (event) {
        el.setAttribute('data-flag',false)
        firstTime = new Date().getTime();
        flag = true;
        downX = event.clientX; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
    });
    el.addEventListener("mousemove", function (event) {
        if (flag) { // 判断是否是鼠标按下滚动元素区域
            var moveX = event.clientX; // 获取移动的x轴
            var scrollX = moveX - downX; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
            this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
        }
    });
    // 鼠标抬起停止拖动
    el.addEventListener("mouseup", function () {
        flag = false;
        lastTime = new Date().getTime();
        if( (lastTime - firstTime) < 150){
            el.setAttribute('data-flag',true)
        }
    });
    // 鼠标离开元素停止拖动
    el.addEventListener("mouseleave", function (event) {
        flag = false;
    });
  }
})
// 竖向拖拽
export const rollY = Vue.directive('rollY', {
    inserted (el) {
        // 获取要绑定事件的元素
        var flag; // 鼠标按下
        var downY; // 鼠标点击的y下标
        var scrollTop; // 当前元素滚动条的偏移量
        let firstTime='',lastTime='';
        el.addEventListener("mousedown", function (event) {
            el.setAttribute('data-flag',false)
            firstTime = new Date().getTime();
            flag = true;
            downY = event.clientY; // 获取到点击的x下标
            scrollTop = this.scrollTop; // 获取当前元素滚动条的偏移量
        });
        el.addEventListener("mousemove", function (event) {
            if (flag) { // 判断是否是鼠标按下滚动元素区域
                var moveY = event.clientY; // 获取移动的x轴
                var scrollY = moveY - downY; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
                this.scrollTop = scrollTop - scrollY // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
            }
        });

        // 鼠标抬起停止拖动
        el.addEventListener("mouseup", function () {
            flag = false;
            lastTime = new Date().getTime();
            if( (lastTime - firstTime) < 150){
                el.setAttribute('data-flag',true)
            }

        });
        // 鼠标离开元素停止拖动
        el.addEventListener("mouseleave", function (event) {
            flag = false;
        });
    }
  })
